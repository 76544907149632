import {Component, OnDestroy, OnInit} from '@angular/core';
import 'devextreme/data/odata/store';
import {HttpClient} from '@angular/common/http';
import CustomStore from "devextreme/data/custom_store";
import {ClientsSearchService} from '../../shared/services/clients-search.service';
import {map} from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import * as moment from 'moment';
import {Client} from '../../shared/model/client';
import {ClientRequest} from '../../shared/model/client-request';
import {DirectoryService} from '../../shared/services';
import notify from 'devextreme/ui/notify';
import {RefillRequest} from '../../shared/model/refill-request';

@Component({
    templateUrl: 'refill.component.html',
    styleUrls: ['refill.component.scss']
})

export class RefillComponent implements OnInit, OnDestroy {
    dataSource: any;
    refillButtonOptions: any;
    expressNo: string = '';

    refill: RefillRequest = new RefillRequest();

    amountRules: Object;
    balanceRur = null;
    clients = [];
    cards;

    refillFormvalidateError: boolean = false;

    constructor(
        private http: HttpClient,
        private clientsSearchService: ClientsSearchService,
        private directoryService: DirectoryService,
    ) {
        this.amountRules = {'X': /[12-9]/};
        this.refillButtonOptions = {
            text: "Пополнить",
            type: "success",
            onClick: (event) => {
                // console.error('+++ onClick() +++ event:', event);
                this.refillFormvalidateError = !event.validationGroup.validate().isValid;
                if (this.refillFormvalidateError) {
                    return;
                }
                this.onRefillFormSubmit(event.event);
            }
        };

    }

    ngOnInit(): void {
        // this.clientsSearchService.updateClientsSearch('at@vgpost.ru');
        this.cards = this.directoryService.getCards;

    }

    ngOnDestroy(): void {
        // this.clientsSearchService.updateClientsSearch(null);
    }

    onRefillFormSubmit = (e) => {
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
        this.clientsSearchService.refill(this.refill)
            .then(response => {
                // console.error('+++ onClientFormSubmit() +++ response:', response);
                if (response.error) {
                    notify({ message: response.error, width: 600, shading: true }, "error", 5000);
                } else {
                    // console.error('+++ findClient() +++ response:', response);
                    const message = `Баланса клиента с номером ${this.refill.expressNo} успешно пополнен`;
                    notify({message: message, width: 600, shading: true}, "success", 5000);
                    this.refill = new RefillRequest();
                }
            });

        e.preventDefault();
    }

    onValueChanged = (e) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        const expressNo = e.value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        this.balanceRur = null;
        this.clients = [];
        if (expressNo && expressNo.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
            this.clientsSearchService.onClientsSearch(expressNo)
                .then((clients) => {
                    // console.error('+++ balance clients:', clients);
                    // console.error('+++ balance clients[0].id:', clients[0].id);
                    this.clients = clients;
                    this.clientsSearchService.clientCabinet(clients[0].id)
                        .then((cabinetResponse) => {
                            const cabinet = cabinetResponse;
                            // console.error('+++ balance cabinet:', cabinet);
                            this.balanceRur = cabinet.result[0].balanceRur;
                        });
                });
        }
    }


}
