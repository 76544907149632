import {Component, NgModule, OnDestroy, OnInit} from '@angular/core';
import 'devextreme/data/odata/store';
import {HttpClient} from '@angular/common/http';
import CustomStore from "devextreme/data/custom_store";
import {ClientsSearchService} from '../../shared/services/clients-search.service';
import {map} from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import * as moment from 'moment';
import {Client} from '../../shared/model/client';
import {ClientRequest} from '../../shared/model/client-request';
import {DirectoryService} from '../../shared/services';
import notify from 'devextreme/ui/notify';
import {BarcodeData} from '../../shared/model/barcode_data';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxCheckBoxModule} from 'devextreme-angular/ui/check-box';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {DxValidatorModule} from 'devextreme-angular/ui/validator';
import {DxValidationGroupModule} from 'devextreme-angular/ui/validation-group';
import {LoginFormComponent} from '../../shared/components';
import {DxNumberBoxModule} from 'devextreme-angular';
import {NgxBarcodeModule} from 'ngx-barcode';
import {FormsModule} from '@angular/forms';

@Component({
    templateUrl: 'labels.component.html',
    styleUrls: ['labels.component.scss'],
})

export class LabelsComponent implements OnInit, OnDestroy {
    dataSource: any;
    barcodeButtonOptions: any;
    barcodeRefreshButtonOptions: any;
    barcodeValue: string = 'US19804FT0104';
    barcodeHeader: string = 'HEADER';
    barcodeHeaderFontSize: string = '24';
    barcodeFooter: string = 'FOOTER';
    barcodeFooterFontSize: string = '24';
    barcodeFooter2: string = 'BEST TO';
    barcodeFooter2FontSize: string = '24';
    barcodeHeaderFontSizeNumber: number = 24;
    barcodeFooterFontSizeNumber: number = 24;
    barcodeFooter2FontSizeNumber: number = 24;

    barcodeData: BarcodeData = new BarcodeData();

    amountRules: Object;
    balanceRur = null;
    clients = [];
    cards;

    barcodeFormvalidateError: boolean = false;

    constructor(
        private http: HttpClient,
        private clientsSearchService: ClientsSearchService,
    ) {
        this.amountRules = {'X': /[12-9]/};
        this.barcodeButtonOptions = {
            text: "Печать",
            type: "success",
            onClick: (event) => {
                // console.error('+++ onClick() +++ event:', event);
                this.barcodeFormvalidateError = !event.validationGroup.validate().isValid;
                if (this.barcodeFormvalidateError) {
                    return;
                }
                this.onRefillFormSubmit(event.event);
                // this.onValueChanged(event.event);
            }
        };

        this.barcodeRefreshButtonOptions = {
            text: "Обновить",
            type: "success",
            onClick: (event) => {
                // console.error('+++ onClick() +++ event:', event);
                this.barcodeFormvalidateError = !event.validationGroup.validate().isValid;
                if (this.barcodeFormvalidateError) {
                    return;
                }
                this.onRefresh(event.event);
            }
        };

    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onRefillFormSubmit = (e) => {
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
        // this.clientsSearchService.refill(this.barcodeData)
        //     .then(response => {
        //         // console.error('+++ onClientFormSubmit() +++ response:', response);
        //         if (response.error) {
        //             notify({ message: response.error, width: 600, shading: true }, "error", 5000);
        //         } else {
        //             // console.error('+++ findClient() +++ response:', response);
        //             const message = `Баланса клиента с номером ${this.barcodeData.expressNo} успешно пополнен`;
        //             notify({message: message, width: 600, shading: true}, "success", 5000);
        //             this.barcodeData = new RefillRequest();
        //         }
        //     });

        e.preventDefault();
    }

    onRefreshFormSubmit = (e) => {
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
        // this.clientsSearchService.refill(this.barcodeData)
        //     .then(response => {
        //         // console.error('+++ onClientFormSubmit() +++ response:', response);
        //         if (response.error) {
        //             notify({ message: response.error, width: 600, shading: true }, "error", 5000);
        //         } else {
        //             // console.error('+++ findClient() +++ response:', response);
        //             const message = `Баланса клиента с номером ${this.barcodeData.expressNo} успешно пополнен`;
        //             notify({message: message, width: 600, shading: true}, "success", 5000);
        //             this.barcodeData = new RefillRequest();
        //         }
        //     });

        e.preventDefault();
    }

    onRefresh = (e) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        const barcodeValue = e.value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        this.balanceRur = null;
        this.clients = [];
        if (barcodeValue && barcodeValue.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ onValueChanged() +++ barcodeValue:', barcodeValue);
            this.barcodeValue = barcodeValue;
        }
    }

    onValueChanged = (e) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        const barcodeValue = e.value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        this.balanceRur = null;
        this.clients = [];
        if (barcodeValue && barcodeValue.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ onValueChanged() +++ barcodeValue:', barcodeValue);
            this.barcodeValue = barcodeValue;

            window.print();
        }
    }

    barcodeHeaderChange = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeHeaderChange() +++ value:', value);
        const barcodeHeader = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (true || (barcodeHeader && barcodeHeader.trim())) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeHeaderChange() +++ barcodeHeader:', barcodeHeader);
            this.barcodeHeader = barcodeHeader.trim();
            // window.print();
        }
    }

    barcodeHeaderFontSizeChange = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeHeaderChange() +++ value:', value);
        const barcodeHeaderFontSize = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (barcodeHeaderFontSize && barcodeHeaderFontSize.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeHeaderFontSizeChange() +++ barcodeHeaderFontSize:', barcodeHeaderFontSize);
            this.barcodeHeaderFontSize = barcodeHeaderFontSize.trim();
            // window.print();
        }
    }

    barcodeFooterFontSizeChange = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeFooterFontSizeChange() +++ value:', value);
        const barcodeFooterFontSize = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (barcodeFooterFontSize && barcodeFooterFontSize.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeFooterFontSizeChange() +++ barcodeFooterFontSize:', barcodeFooterFontSize);
            this.barcodeFooterFontSize = barcodeFooterFontSize.trim();
            // window.print();
        }
    }

    barcodeFooter2FontSizeChange = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeFooter2FontSizeChange() +++ value:', value);
        const barcodeFooter2FontSize = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (barcodeFooter2FontSize && barcodeFooter2FontSize.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeFooter2FontSizeChange() +++ barcodeFooter2FontSize:', barcodeFooter2FontSize);
            this.barcodeFooter2FontSize = barcodeFooter2FontSize.trim();
            // window.print();
        }
    }

    barcodeHeaderFontSizeNumberValueChanged = (event) => {
        const barcodeHeaderFontSizeNumber = event.value;
        this.barcodeHeaderFontSize = '' + barcodeHeaderFontSizeNumber;
    }

    barcodeHeaderFontSizeNumberOnInput = (event) => {
        const barcodeHeaderFontSizeNumber = Number(event.event.target.value);
        this.barcodeHeaderFontSizeNumber = barcodeHeaderFontSizeNumber;
        this.barcodeHeaderFontSize = '' + this.barcodeHeaderFontSizeNumber;
    }

    barcodeFooterFontSizeNumberOnInput = (event) => {
        const barcodeFooterFontSizeNumber = Number(event.event.target.value);
        this.barcodeFooterFontSizeNumber = barcodeFooterFontSizeNumber;
        this.barcodeFooterFontSize = '' + this.barcodeFooterFontSizeNumber;
    }

    barcodeFooter2FontSizeNumberOnInput = (event) => {
        const barcodeFooter2FontSizeNumber = Number(event.event.target.value);
        this.barcodeFooter2FontSizeNumber = barcodeFooter2FontSizeNumber;
        this.barcodeFooter2FontSize = '' + this.barcodeFooter2FontSizeNumber;
    }

    barcodeFooterFontSizeNumberValueChanged = (event) => {
        const barcodeFooterFontSizeNumber = event.value;
        this.barcodeFooterFontSize = '' + barcodeFooterFontSizeNumber;
    }

    barcodeFooter2FontSizeNumberValueChanged = (event) => {
        const barcodeFooter2FontSizeNumber = event.value;
        this.barcodeFooter2FontSize = '' + barcodeFooter2FontSizeNumber;
    }

    barcodeFooterChange = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeFooterChange() +++ value:', value);
        const barcodeFooter = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (true || (barcodeFooter && barcodeFooter.trim())) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeFooterChange() +++ barcodeFooter:', barcodeFooter);
            this.barcodeFooter = barcodeFooter.trim();
            // window.print();
        }
    }

    barcodeFooter2Change = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeFooter2Change() +++ value:', value);
        const barcodeFooter2 = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (true || (barcodeFooter2 && barcodeFooter2.trim())) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeFooter2Change() +++ barcodeFooter2:', barcodeFooter2);
            this.barcodeFooter2 = barcodeFooter2.trim();
            // window.print();
        }
    }

    barcodeValueChange = (value) => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        console.error('+++ barcodeValueChange() +++ value:', value);
        const barcodeValue = value;
        // console.error('+++ onValueChanged() +++ expressNo:', expressNo);
        if (barcodeValue && barcodeValue.trim()) {
            // console.error('+++ onValueChanged() +++ this.refill.expressNo:', this.refill.expressNo);
            console.error('+++ barcodeValueChange() +++ barcodeValue:', barcodeValue);
            this.barcodeValue = barcodeValue;
            // window.print();
        }
    }

    barcodePrint = () => {
        // console.error('+++ onValueChanged() +++ this.expressNo:', this.expressNo);
        // console.error('+++ onValueChanged() +++ this.refill:', this.refill);
        // console.error('+++ onValueChanged() +++ e:', e);
        // console.error('+++ barcodePrint() +++');
        // window.print();

        // const printContents = document.getElementById('print-section').innerHTML;
        // const originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        window.print();
        // document.body.innerHTML = originalContents;
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        NgxBarcodeModule,
        FormsModule,
    ],
    declarations: [LabelsComponent],
    exports: [LabelsComponent]
})
export class LabelsModule {
}
