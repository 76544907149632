export class ClientRequest {
    department: number; // 2
    archived: boolean = false;
    removed: boolean = false;
    password?: string = null;
    email: string;
    id: number = null;
    phone: string;
    comments: string = null;
    express: boolean = true;
    expressNo?: string = null;
    passpNo: string = null;
    version: number = null;
    vip: boolean = false;
    autoBooking: boolean = true;
    discountComment?: string = null;
    passpIssueDate: string = null; // "979084800000"
    passpIssueOrg: string = null;
    firstName: string;
    lastName: string;
    secondName: string = null;
    person: boolean = true;
    code: string = null;
    sendEmail: boolean = false;
    sendSms: boolean = false;
}
