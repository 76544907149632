import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {LoginRequest} from '../model/login-request';
import {UtilService} from './util.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, filter, switchMap} from 'rxjs/operators';
import {Client} from '../model/client';
import {FilterResponse} from '../model/filter-response';
import {ClientRequest} from '../model/client-request';
import notify from 'devextreme/ui/notify';
import {ShortResponse} from '../model/short-response';
import {RefillRequest} from '../model/refill-request';
import * as moment from 'moment';
import {ConsigneesResponse} from '../model/consigneesResponse';
import {PositionsResponse} from '../model/positionsResponse';
import {CounterAgentResponse} from '../model/counterAgentResponse';

@Injectable()
export class ClientsSearchService {

    private clientsSearch$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private utilService: UtilService,
        private http: HttpClient,
    ) {
    }

    updateClientsSearch = (clientsSearchRequest: string) => {
        this.clientsSearch$.next(clientsSearchRequest);
    }
    onUpdateClientsSearch(): Observable<Client[]> {
        return this.clientsSearch$
            .pipe(
                filter((value) => value !== null),
                switchMap((clientsSearchRequest) => {
                    const o = {
                        removed: false,
                        filter_code: clientsSearchRequest,
                        act: 'filter',
                        id: null,
                        type: null,
                        version: null,
                        archived: false,
                        code: clientsSearchRequest,
                    };
                    const params = {
                        m: JSON.stringify(o),
                        act: 'filter'
                    };
                    // return this.http.post<FilterResponse>('https://lk.vgexpress.ru/tms/cwp/Client', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
                    return this.http.post<FilterResponse>('/tms/cwp/Client', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
                        .pipe(
                            map(response => {
                                // console.error('+++ onUpdateClientsSearch() +++ response:', response);
                                return response.result;
                            }),
                            catchError(error => {
                                // console.error('+++ onUpdateClientsSearch() +++ catchError error:', error);
                                return of([]); // 'Вернуть пустой массив!!!'
                            })
                        );
                })
            );
    }

    onClientsSearch(clientsSearchRequest): Promise<Client[]> {
        if (clientsSearchRequest.trim()) {
            const o = {
                removed: false,
                filter_code: clientsSearchRequest,
                act: 'filter',
                id: null,
                type: null,
                version: null,
                archived: false,
                code: clientsSearchRequest,
            };
            const params = {
                m: JSON.stringify(o),
                act: 'filter'
            };
            // return this.http.post<FilterResponse>('https://lk.vgexpress.ru/tms/cwp/Client', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
            return this.http.post<FilterResponse>('/tms/cwp/Client', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
                .toPromise()
                .then(response => {
                    // console.error('+++ onUpdateClientsSearch() +++ response:', response);
                    return response.result;
                });
            // .catch(error => {
            //         // console.error('+++ onUpdateClientsSearch() +++ catchError error:', error);
            //         return of([]); // 'Вернуть пустой массив!!!'
            //     })
            // );
        } else {
            return of([]).toPromise();
        }
    }

    saveClient(client: ClientRequest) {
        // console.error('+++ saveClient() +++ client:', client);
        const params = {
            m: JSON.stringify(client),
            act: client.id ? 'update' : 'createExpress'
        };

        return this.http.post<ShortResponse>('/tms/cwp/Client', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
            .toPromise()
            .then(response => {
                // console.error('+++ onUpdateClientsSearch() +++ response:', response);
                return response;
            });
    }

    findClient(clientId: number) {
        // console.error('+++ findClient() +++ clientId:', clientId);
        const o = {
            id: clientId,
            code: null,
        };
        const params = {
            m: JSON.stringify(o),
            act: 'find'
        };

        return this.http.post<ShortResponse>('/tms/cwp/Client', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
            .toPromise()
            .then(response => {
                // console.error('+++ findClient() +++ response:', response);
                return response;
            });
    }

    findClientConsignees(clientId: number): Promise<ConsigneesResponse> {
        if (clientId) {
            const o = {
                client: clientId,
                srcCity: null,
                agent: null,
                id: null,
                type: 'consignee',
                dstCity: null,
                code: null,
            };
            const params = {
                m: JSON.stringify(o),
                act: 'findConsignee'
            };

            return this.http.post<ConsigneesResponse>('/tms/cwp/ExpressShipping', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
                .toPromise()
                .then(response => {
                    return response;
                });
        } else {
            const response: ConsigneesResponse = {
                error: null,
                result: {
                    first: [],
                    second: [],
                }
            };
            return of(response).toPromise();
        }
    }

    findClientPositions(clientId: number): Promise<PositionsResponse> {
        if (clientId) {
            const o = {
                consignee: null,
                id: null,
                type: 'location',
                express: true,
                client: clientId,
                code: null,
            };
            const params = {
                m: JSON.stringify(o),
                act: 'findShort'
            };

            return this.http.post<PositionsResponse>('/tms/cwp/ExpressPosition', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
                .toPromise()
                .then(response => {
                    return response;
                });
        } else {
            const response: PositionsResponse = {
                error: null,
                result: [],
            };
            return of(response).toPromise();
        }
    }

    findCounterAgent(id: number): Promise<CounterAgentResponse> {
        if (id) {
            const o = {
                id: id,
                version: null,
                code: null,
            };
            const params = {
                m: JSON.stringify(o),
                act: 'find'
            };

            return this.http.post<CounterAgentResponse>('/tms/cwp/CounterAgent', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
                .toPromise()
                .then(response => {
                    return response;
                });
        } else {
            const response: CounterAgentResponse = {
                error: null,
                result: [],
            };
            return of(response).toPromise();
        }
    }

    refill(refill: RefillRequest) {
        // console.error('+++ saveClient() +++ client:', client);
        const params = {
            m: JSON.stringify(refill),
            act: 'refill'
        };

        return this.http.post<ShortResponse>('/tms/cwp/RefillBalance', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
            .toPromise()
            .then(response => {
                // console.error('+++ onUpdateClientsSearch() +++ response:', response);
                return response;
            });
    }

    clientCabinet(clientId: number) {
        // console.error('+++ findClient() +++ clientId:', clientId);
        const o = {
            client: clientId,
            // dateFrom:  '06.08.2020 00:00',
            dateFrom:  moment().startOf('day').format('DD.MM.YYYY HH:mm'), // '06.08.2020 00:00'
            dateTo:  moment().endOf('day').format('DD.MM.YYYY HH:mm'), // '06.08.2020 00:00'
            // dateTo:  '06.08.2020 23:59',

        };
        const params = {
            m: JSON.stringify(o),
            act: 'filter'
        };

        return this.http.post<ShortResponse>('/tms/cwp/Cabinet', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
            .toPromise()
            .then(response => {
                // console.error('+++ findCabinet() +++ response:', response);
                return response;
            });
    }

}

