export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Клиенты',
        path: '/clients',
        icon: 'search'
    },
    {
        text: 'Пополнение',
        path: '/refill',
        icon: 'home'
    },
    {
        text: 'Наклейки',
        path: '/labels',
        icon: 'increaseindent'
    },
    // {
    //   text: 'Examples',
    //   icon: 'folder',
    //   items: [
    //     {
    //       text: 'Profile',
    //       path: '/profile'
    //     },
    //     {
    //       text: 'Display Data',
    //       path: '/display-data'
    //     }
    //   ]
    // }
];
