<h2 class="content-block">Клиенты</h2>
<div class="content-block">

    <dx-button
        class="mb-1"
        stylingMode="contained"
        text="Создать клиента"
        icon="fa fa-user-plus"
        type="normal"
        (onClick)="openNewClientForm($event)">
    </dx-button>

    <div *ngIf="!showNewClientForm">
        <div class="dx-field pb-1">
    <!--        <div class="dx-field-label">Поиск</div>-->
            <div class="dx-field-value width-30">
                <dx-text-box
                    [(value)]="searchValue"
                    placeholder="Код, телефон, e-mail..."
                    [showClearButton]="true"
                    (onEnterKey)="onSearch($event)"
                    (onValueChanged)="onValueChanged($event)"
                >
        <!--            stylingMode="filled"-->
        <!--            [(mode)]="'search'"-->
        <!--            [(mode)]="passwordMode"-->
                    <dxi-button name="clear"></dxi-button>
                    <dxi-button name="search" location="after" [options]="searchButton"></dxi-button>
                </dx-text-box>
            </div>
        </div>
        <dx-data-grid class="dx-card wide-card width-100"
            [dataSource]="customDataSource"
            [showColumnLines]="true"
            [showRowLines]="true"
            [showBorders]="false"
            [rowAlternationEnabled]="true"
            [focusedRowEnabled]="true"
            [columnAutoWidth]="true"
            [columnHidingEnabled]="false"
            [paging]="false"
        >
    <!--        [focusedRowIndex]="0"-->

        <!--    <dxo-paging [pageSize]="10"></dxo-paging>-->
        <!--    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>-->
        <!--    <dxo-filter-row [visible]="true"></dxo-filter-row>-->

        <!--    <dxi-column-->
        <!--        dataField="id"-->
        <!--        [width]="190"-->
        <!--        visible="false"-->
        <!--    >-->
        <!--    </dxi-column>-->
            <dxi-column
                dataField="expressNo"
                caption="Код"
                [cellTemplate]="'expressNoCellTemplate'"
                [width]="190"
            >
                <div *dxTemplate="let cell of 'expressNoCellTemplate'">
                    <a href="" (click)="expressNoClick(cell, $event)"><div>{{ cell.text }}</div></a>
                </div>
<!--                [cellTemplate]="expressNoCellTemplate"-->
            </dxi-column>
            <dxi-column
                dataField="email"
                caption="E-mail"
                [width]="190"
            >
            </dxi-column>
            <dxi-column
                dataField="phone"
                caption="Phone"
                [width]="190"
            >
            </dxi-column>
            <dxi-column
                dataField="firstName"
                caption="Имя"
                [width]="190"
            >
            </dxi-column>
            <dxi-column
                dataField="lastName"
                caption="Фамилия"
                [width]="190"
            >
            </dxi-column>
            <dxi-column
                dataField="createDate"
                dataType="number"
                [customizeText]="dateColumns_customizeText"
                caption="Дата регистрации"
                [width]="190"
            >
            </dxi-column>
            <dxi-column
                dataField="lastLoginDate"
                dataType="number"
                [customizeText]="dateColumns_customizeText"
                caption="Время последнего входа"
                [width]="190"
            >
            </dxi-column>
        <!--    <dxi-column-->
        <!--        dataField="Task_Subject"-->
        <!--        [width]="190"-->
        <!--        caption="Subject"-->
        <!--        [hidingPriority]="8">-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="Task_Status"-->
        <!--        caption="Status"-->
        <!--        [hidingPriority]="6">-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="Task_Priority"-->
        <!--        caption="Priority"-->
        <!--        [hidingPriority]="5">-->
        <!--        <dxo-lookup-->
        <!--            [dataSource]="priority"-->
        <!--            valueExpr="value"-->
        <!--            displayExpr="name">-->
        <!--        </dxo-lookup>-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="ResponsibleEmployee.Employee_Full_Name"-->
        <!--        caption="Assigned To"-->
        <!--        [allowSorting]="false"-->
        <!--        [hidingPriority]="7">-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="Task_Start_Date"-->
        <!--        caption="Start Date"-->
        <!--        dataType="date"-->
        <!--        [hidingPriority]="3">-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="Task_Due_Date"-->
        <!--        caption="Due Date"-->
        <!--        dataType="date"-->
        <!--        [hidingPriority]="4">-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="Task_Priority"-->
        <!--        caption="Priority"-->
        <!--        [hidingPriority]="1">-->
        <!--    </dxi-column>-->
        <!--    <dxi-column-->
        <!--        dataField="Task_Completion"-->
        <!--        caption="Completion"-->
        <!--        [hidingPriority]="0">-->
        <!--    </dxi-column>-->
        </dx-data-grid>
    </div>
    <app-client [client]="client" (submit)="onClientFormSubmit($event)" *ngIf="showNewClientForm"></app-client>
    <div id="form-container" *ngIf="false && showNewClientForm">
        <dx-form id="form"
                 action="save-client"
                 [colCount]="1"
                 [labelLocation]="'left'"
                 [(formData)]="client"
                 validationGroup="saveClientValidationGroup"
        >
            <dxi-item dataField="phone" [editorOptions]="{ mask: '+X (X00) 000-0000', maskRules: phoneRules }">
                <dxi-validation-rule type="required" message="Phone is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="email">
                <dxi-validation-rule
                    type="required"
                    message="Email is required">
                </dxi-validation-rule>
                <dxi-validation-rule
                    type="email"
                    message="Email is invalid">
                </dxi-validation-rule>
<!--                <dxi-validation-rule-->
<!--                    type="async"-->
<!--                    message="Email is already registered"-->
<!--                    [validationCallback]="asyncValidation">-->
<!--                </dxi-validation-rule>-->
            </dxi-item>
            <dxi-item dataField="firstName">
                <dxi-validation-rule type="required" message="First name is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="lastName">
                <dxi-validation-rule type="required" message="Last name is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="secondName">
            </dxi-item>
            <dxi-item dataField="department" editorType="dxSelectBox" [editorOptions]="{ items: departments, searchEnabled: true, value: '', valueExpr: 'id', displayExpr: 'fullName' }">
                <dxi-validation-rule type="required" message="Department is required"></dxi-validation-rule>
            </dxi-item>
            <!--<div class="dx-field">
                <div class="dx-field-label">Custom templates</div>
                <div class="dx-field-value">
                    <dx-select-box
                        id="custom-templates"
                        [dataSource]="departments"
                        displayExpr="Name"
                        valueExpr="ID"
                        [value]="products[3].ID"
                        fieldTemplate="field"
                    >
                        <div *dxTemplate="let data of 'field'">
                            <div class="custom-item">
                                <img src="{{data && data.ImageSrc}}"/>
                                <dx-text-box class="product-name"
                                             [value]="data && data.Name"
                                             [readOnly]="true"
                                ></dx-text-box>
                            </div>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                            <div class="custom-item">
                                <img src="{{data.ImageSrc}}" />
                                <div class="product-name">
                                    {{data.Name}}
                                </div>
                            </div>
                        </div>
                    </dx-select-box>
                </div>
            </div>-->
<!--            <dxi-item dataField="Position" editorType="dxSelectBox" [editorOptions]="{ items: positions, searchEnabled: true, value: '' }">-->
<!--                <dxi-validation-rule type="required" message="Position is required"></dxi-validation-rule>-->
<!--            </dxi-item>-->
<!--            <dxi-item dataField="HireDate" editorType="dxDateBox" [editorOptions]="{ value: null, width: '100%' }">-->
<!--                <dxi-validation-rule type="required" message="Hire date is required"></dxi-validation-rule>-->
<!--            </dxi-item>-->
<!--            <dxi-item dataField="BirthDate" editorType="dxDateBox" [editorOptions]="{ disabled: true, width: '100%' }"></dxi-item>-->
<!--            <dxi-item dataField="Address"></dxi-item>-->
<!--            <dxi-item dataField="Notes" editorType="dxTextArea" [editorOptions]="{ height: 90 }" [colSpan]="2"></dxi-item>-->
<!--            <div class="dx-fieldset">-->
                <dx-validation-summary validationGroup="saveClientValidationGroup"></dx-validation-summary>
<!--            </div>-->
            <dxi-item
                itemType="button"
                alignment="left"
                [buttonOptions]="saveClientButtonOptions"
                validationGroup="saveClientValidationGroup"
            >
            </dxi-item>
<!--            <div class="dx-field">-->
<!--                <div *ngIf="saveClientFormvalidateError">Заполните обязательные поля</div>-->
<!--            </div>-->
        </dx-form>
    </div>
</div>
