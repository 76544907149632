import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {UtilService} from './util.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, filter} from 'rxjs/operators';
import {FilterResponse} from '../model/filter-response';
import {Department} from '../model/department';
import {AuthService} from './auth.service';

@Injectable()
export class DirectoryService {

    private departments: Department[] = [];
    private cards: string[] = [
        'BER VC 6502',
        'ALFA MC 7258',
        'TINKOFF MC 1925',
        'VTB MC 2705',
        'BM Wire',
        'Petty Cash',
    ];

    constructor(
        private utilService: UtilService,
        private authService: AuthService,
        private http: HttpClient,
    ) {
        // console.error('+++ DirectoryService constructor() +++');
        this.authService.onLoggedIn()
            .pipe(map((logged) => {
                // console.error('+++ onLoggedIn() +++ logged:', logged);
                return logged;
            }))
            .pipe(filter((logged) => logged !== null && logged === true))
            .subscribe((logged) => {
                // console.error('+++ onLoggedIn() +++ logged:', logged);
                this.loadDepartments();
            });
    }

    get getDepartments(): Department[] {
        return this.departments;
    }

    get getCards(): string[] {
        return this.cards;
    }

    loadDepartments(): void {
        const o = {
            id: null,
            dType: 'department',
        };
        const params = {
            m: JSON.stringify(o),
        };
        this.http.post<FilterResponse>('/tms/dictHlp', this.utilService.toFormUrlEncoded(params), {headers: this.utilService.headersFormUrlencoded})
            .pipe(
                map(response => {
                    // console.error('+++ loadDepartments() +++ response:', response);
                    return response.result;
                }),
                catchError(error => {
                    // console.error('+++ onUpdateClientsSearch() +++ catchError error:', error);
                    return of([]); // 'Вернуть пустой массив!!!'
                })
            )
            .subscribe((departments) => {
                // console.error('+++ loadDepartments() +++ departments:', departments);
                this.departments = departments;
            });
    }

}

