import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AuthService, ScreenService, AppInfoService, DirectoryService} from './shared/services';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(
    private authService: AuthService,
    private directoryService: DirectoryService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private router: Router,
  ) { }

  isAutorized() {
    return this.authService.isLoggedIn;
  }

  ngOnInit(): void {
    // console.error('+++ ngOnInit() +++');
    // this.subscriptions.add(this.authService.logoff().subscribe(isAuthenticated => {
    //     console.error('+++ logoff() +++ response:', isAuthenticated);
    // }));

    this.subscriptions.add(this.authService.onUpdateCheckSession().subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        // window.location.href = 'https://lk.vgexpress.ru/tms/tms-client.jsp';
          this.authService.logoff(false).subscribe(isAuthenticated => {
              // console.error('+++ logoff() +++ response:', isAuthenticated);
          });
          this.router.navigate(['/login-form']);
      } else {
        this.router.navigate(['/']);
      }
      // this.isAuthenticated = isAuthenticated;
      // console.error('+++ this.isAuthenticated:', this.isAuthenticated);
    }));

    this.authService.updateCheckSession();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.authService.updateCheckSession(true);
  }

}
