    <div id="form-container" *ngIf="consignee">
        <dx-form id="form"
                 action="save-consignee"
                 [colCount]="1"
                 [labelLocation]="'left'"
                 [(formData)]="consignee"
                 validationGroup="saveConsigneeValidationGroup"
        >
<!--            [customizeItem]="form_customizeItem"-->
            <dxi-item itemType="group" [colSpan]="1" [colCount]="1">
                <dxi-item dataField="person"
                          [label]="{text: 'Тип'}"
                          [editorType]="'dxRadioGroup'"
                          [editorOptions]="{items: personTypes, value: personTypes[0], layout: 'vertical'}"
                >
                </dxi-item>
                <dxi-item dataField="firstName" [label]="{text: 'Имя'}">
                    <dxi-validation-rule type="required" message="First name is required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="lastName" [label]="{text: 'Фамилия'}">
                    <dxi-validation-rule type="required" message="Last name is required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="secondName" [label]="{text: 'Отчество'}">
                </dxi-item>
                <dxi-item dataField="address" [label]="{text: 'Адрес'}">
                </dxi-item>
                <dxi-item dataField="city" [label]="{text: 'Город'}">
                </dxi-item>
                <dxi-item dataField="zip" [label]="{text: 'Индекс'}">
                </dxi-item>
                <dxi-item dataField="citizenship" [label]="{text: 'Гражданство'}">
                </dxi-item>
                <dxi-item dataField="inn" [label]="{text: 'ИНН'}">
                </dxi-item>

                <dxi-item itemType="group" [colSpan]="1" [colCount]="1" caption="Паспорт">
                    <dxi-item dataField="passpNo" [label]="{text: '№ паспорта'}">
                    </dxi-item>
                    <dxi-item dataField="passpIssueDateFormValue"
                              [label]="{text: 'Когда выдан'}"
                              [editorType]="'dxDateBox'"
                    >
<!--
                        [editorOptions]="{
                        type: 'calendar',
                        value: '2017-10-12',
                        useMaskBehavior: true,
                        }"
-->
                    </dxi-item>
                </dxi-item>

                <dxi-item dataField="phone" [label]="{text: 'Телефон'}" [editorOptions]="{ mask: '+X (X00) 000-0000', maskRules: phoneRules }">
                    <dxi-validation-rule type="required" message="Phone is required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="email">
                    <dxi-validation-rule
                        type="required"
                        message="Email is required">
                    </dxi-validation-rule>
                    <dxi-validation-rule
                        type="email"
                        message="Email is invalid">
                    </dxi-validation-rule>
<!--                <dxi-validation-rule-->
<!--                    type="async"-->
<!--                    message="Email is already registered"-->
<!--                    [validationCallback]="asyncValidation">-->
<!--                </dxi-validation-rule>-->
                </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" [colSpan]="1" [colCount]="1" caption="Адрес регистрации">
<!--                <dxi-item dataField="regAdministrativeAreaLvl2"-->
<!--                          [label]="{text: 'Регион'}"-->
<!--                          [editorType]="'dxAutocomplete'"-->
<!--                          [editorOptions]="{-->
<!--                            dataSource: regionAddressFiasCustomDataSource,-->
<!--                          }"-->
<!--                >-->
<!--                </dxi-item>-->
                <div class="dx-field">
                    <div class="dx-field-label">Регион</div>
                    <div class="dx-field-value" *ngIf="consignee?.registrationAddress?.administrativeAreaLvl1">
                        <dx-autocomplete
                            placeholder="Регион..."
                            [(value)]="consignee.registrationAddress.administrativeAreaLvl1"
                            [dataSource]="regionAddressFiasCustomDataSource"
                            (onSelectionChanged)="regionSelected($event)"
                            (onBlur)="regionSelected($event)"
                            itemTemplate="region"
                            [valueExpr]="'offname'"
                        >
                            <div *dxTemplate="let itemObj  of 'region'">
                                {{itemObj.offname}}
                            </div>
                        </dx-autocomplete>
                    </div>
                </div>
            </dxi-item>


            <dx-validation-summary validationGroup="saveConsigneeValidationGroup"></dx-validation-summary>
            <dxi-item itemType="group" [colSpan]="2" [colCount]="2">
                <dxi-item
                    itemType="button"
                    alignment="left"
                    [buttonOptions]="closeConsigneeButtonOptions"
                    validationGroup="saveConsigneeValidationGroup"
                >
                </dxi-item>
                <dxi-item
                    itemType="button"
                    alignment="right"
                    [buttonOptions]="saveConsigneeButtonOptions"
                    validationGroup="saveConsigneeValidationGroup"
                >
                </dxi-item>
            </dxi-item>
<!--            <div class="dx-field">-->
<!--                <div *ngIf="saveClientFormvalidateError">Заполните обязательные поля</div>-->
<!--            </div>-->
        </dx-form>
    </div>
