import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import 'devextreme/data/odata/store';
import {AddressService, ClientsSearchService, DirectoryService} from '../../shared/services';
import {CounterAgent} from '../../shared/model/counterAgent';
import { formatDate, parseDate } from "devextreme/localization";
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-consignee',
    templateUrl: 'consignee.component.html',
    styleUrls: ['consignee.component.scss']
})

export class ConsigneeComponent implements OnInit, OnDestroy {

    consignee: CounterAgent;
    regionAddressFiasCustomDataSource: DataSource;

    _consigneeId: number;
    @Input()
    set consigneeId(consigneeId: number) {
        // console.error('+++ consigneeId() +++ consigneeId:', consigneeId);
        this.consignee = new CounterAgent(); // null;
        this._consigneeId = consigneeId;
        this.clientsSearchService.findCounterAgent(consigneeId)
            .then((counterAgentResponse) => {
                this.consignee = ((counterAgentResponse || {}).result || {})[0];
                console.error('+++ consigneeId() +++ this.consignee:', this.consignee);
                // this.consignee.passpIssueDate = new Date().toString();
                // this.consignee.passpIssueDate = '2017-10-12';
                this.consignee.passpIssueDateFormValue = new Date(Number(this.consignee.passpIssueDate));
            });
    }

    @Output() close = new EventEmitter<number>();

    _date: Date = new Date();
    get dateCustom() {
        return formatDate(this._date, "shortDate")
    }
    set dateCustom(value) {
        this._date = parseDate(value, "shortDate")
    }

    phoneRules: Object;

    departments;

    saveConsigneeFormValidateError: boolean = false;

    saveConsigneeButtonOptions: any;
    closeConsigneeButtonOptions: any;

    personTypes = [
        'Физическое лицо',
        'Юридическое лицо',
    ];

    constructor(
        private addressService: AddressService,
        private clientsSearchService: ClientsSearchService,
    ) {
        this.phoneRules = {'X': /[12-9]/};

        this.saveConsigneeButtonOptions = {
            text: "Сохранить",
            type: "success",
            onClick: (event) => {
                event.event.preventDefault();
                // console.error('+++ onClick() +++ event:', event);
                this.saveConsigneeFormValidateError = !event.validationGroup.validate().isValid;
                if (this.saveConsigneeFormValidateError) {
                    return;
                }
                this.onConsigneeFormSubmit(event.event);
            }
        };

        this.closeConsigneeButtonOptions = {
            text: "Закрыть",
            type: "outline",
            onClick: (event) => {
                event.event.preventDefault();
                // console.error('+++ onClick() +++ event:', event);
                this.onConsigneeFormClose();
            }
        };

        this.regionAddressFiasCustomDataSource = new DataSource({
            store: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                    return this.addressService.onAddressRegionSearch(this.consignee.registrationAddress.administrativeAreaLvl1)
                        // .pipe(map((clients) => {
                        //     console.error('+++ clients:', clients);
                        //     return clients;
                        // }))
                        // // .subscribe((clients) => {
                        // .toPromise()
                        .then((clients) => {
                            // return {
                            //     data: clients,
                            //     totalCount: clients.length,
                            // summary: response.summary,
                            // groupCount: response.groupCount
                            // };
                            // console.error('+++ clients:', clients);
                            return clients;
                            // return {
                            //     data: clients,
                            // }
                        });
                    // }
                },
            })
        });


    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    onConsigneeFormSubmit = (event) => {
        event.preventDefault();
        console.error('+++ this.consignee:', this.consignee);
        // this.submit.emit(this.client);
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
    }

    onConsigneeFormClose = () => {
        this.close.emit(this._consigneeId);
    }

    regionSelected = (event) => {
        console.error('+++ regionSelected +++ event:', event);
        console.error('+++ regionSelected +++ this.consignee:', this.consignee);
    }

}
