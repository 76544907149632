<h2 class="content-block">Наклейка</h2>
<div class="content-block">

    <div class="barcode-print" id="print-section">
        <div class="barcode-title" [style.fontSize]="barcodeHeaderFontSize + 'px'">{{barcodeHeader}}</div>
        <div class="barcode-image">
            <ngx-barcode
                [bc-element-type]="'svg'"
                [bc-format]="'CODE128'"
                [bc-width]="2"
                [bc-height]="100"
                [bc-display-value]="true"
                [bc-text-align]="'center'"
                [bc-text-position]="'bottom'"
                [bc-text-margin]="2"
                [bc-font-size]="20"
                [bc-font]="'monospace'"
                [bc-background]="'#ffffff'"
                [bc-margin]="10"
                [bc-value]="barcodeValue"
            ></ngx-barcode>
        </div>
        <div class="barcode-title" [style.fontSize]="barcodeFooterFontSize + 'px'">{{barcodeFooter}}</div>
        <div class="barcode-title" [style.fontSize]="barcodeFooter2FontSize + 'px'">{{barcodeFooter2}}</div>
    </div>
<!--    <div>-->
<!--        <input type="text" [ngModel]="barcodeTitle" (ngModelChange)="barcodeTitleChange($event)" />-->
<!--    </div>-->
<!--    <div>-->
<!--        <input type="text" [ngModel]="barcodeValue" (ngModelChange)="barcodeValueChange($event)" />-->
<!--    </div>-->
<!--    <button printSectionId="print-section" ngxPrint>Распечатать</button>-->
<!--    <div>-->
<!--        <button (click)="barcodePrint()">Распечатать</button>-->
<!--    </div>-->

    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">
        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">
            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">
                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeHeader">
                    <span class="dx-field-item-label-content" style="width: 80px;">
                        <span class="dx-field-item-label-text">Header</span>
<!--                        <span class="dx-field-item-required-mark">&nbsp;*</span>-->
                    </span>
                </label>
                <div class="dx-field-item-content dx-field-item-content-location-right">
                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">
                        <div class="dx-texteditor-container">
                            <div class="dx-texteditor-input-container">
                                <input [ngModel]="barcodeHeader" (ngModelChange)="barcodeHeaderChange($event)" autocomplete="off" id="barcodeHeader" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeHeader" tabindex="0" role="textbox" aria-required="true">
                                <div data-dx_placeholder="" class="dx-placeholder"></div>
                            </div>
                            <div class="dx-texteditor-buttons-container">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!--    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">-->
<!--        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">-->
<!--            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">-->
<!--                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeHeaderFontSize">-->
<!--                    <span class="dx-field-item-label-content" style="width: 80px;">-->
<!--                        <span class="dx-field-item-label-text">Header font size</span>-->
<!--                        <span class="dx-field-item-required-mark">&nbsp;*</span>-->
<!--                    </span>-->
<!--                </label>-->
<!--                <div class="dx-field-item-content dx-field-item-content-location-right">-->
<!--                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">-->
<!--                        <div class="dx-texteditor-container">-->
<!--                            <div class="dx-texteditor-input-container">-->
<!--                                <input [ngModel]="barcodeHeaderFontSize" (ngModelChange)="barcodeHeaderFontSizeChange($event)" autocomplete="off" id="barcodeHeaderFontSize" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeHeaderFontSize" tabindex="0" role="textbox" aria-required="true">-->
<!--                                <div data-dx_placeholder="" class="dx-placeholder"></div>-->
<!--                            </div>-->
<!--                            <div class="dx-texteditor-buttons-container">-->
<!--                                <div></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <dx-number-box [(value)]="barcodeHeaderFontSizeNumber" placeholder="Footer2 Font Size" width="100%" (onValueChanged)="barcodeHeaderFontSizeNumberValueChanged($event)" (onInput)="barcodeHeaderFontSizeNumberOnInput($event)" min="8" max="50">
        <dx-validator>
            <dxi-validation-rule type="required" message="Font size is required"></dxi-validation-rule>
        </dx-validator>
    </dx-number-box>

    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">
        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">
            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">
                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeValue">
                    <span class="dx-field-item-label-content" style="width: 80px;">
                        <span class="dx-field-item-label-text">Barcode</span>
                        <span class="dx-field-item-required-mark">&nbsp;*</span>
                    </span>
                </label>
                <div class="dx-field-item-content dx-field-item-content-location-right">
                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">
                        <div class="dx-texteditor-container">
                            <div class="dx-texteditor-input-container">
                                <input [ngModel]="barcodeValue" (ngModelChange)="barcodeValueChange($event)" autocomplete="off" id="barcodeValue" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeValue" tabindex="0" role="textbox" aria-required="true">
                                <div data-dx_placeholder="" class="dx-placeholder"></div>
                            </div>
                            <div class="dx-texteditor-buttons-container">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">
        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">
            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">
                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeFooter">
                    <span class="dx-field-item-label-content" style="width: 80px;">
                        <span class="dx-field-item-label-text">Footer</span>
<!--                        <span class="dx-field-item-required-mark">&nbsp;*</span>-->
                    </span>
                </label>
                <div class="dx-field-item-content dx-field-item-content-location-right">
                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">
                        <div class="dx-texteditor-container">
                            <div class="dx-texteditor-input-container">
                                <input [ngModel]="barcodeFooter" (ngModelChange)="barcodeFooterChange($event)" autocomplete="off" id="barcodeFooter" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeFooter" tabindex="0" role="textbox" aria-required="true">
                                <div data-dx_placeholder="" class="dx-placeholder"></div>
                            </div>
                            <div class="dx-texteditor-buttons-container">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!--    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">-->
<!--        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">-->
<!--            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">-->
<!--                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeFooterFontSize">-->
<!--                    <span class="dx-field-item-label-content" style="width: 80px;">-->
<!--                        <span class="dx-field-item-label-text">Footer font size</span>-->
<!--                        <span class="dx-field-item-required-mark">&nbsp;*</span>-->
<!--                    </span>-->
<!--                </label>-->
<!--                <div class="dx-field-item-content dx-field-item-content-location-right">-->
<!--                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">-->
<!--                        <div class="dx-texteditor-container">-->
<!--                            <div class="dx-texteditor-input-container">-->
<!--                                <input [ngModel]="barcodeFooterFontSize" (ngModelChange)="barcodeFooterFontSizeChange($event)" autocomplete="off" id="barcodeFooterFontSize" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeFooterFontSize" tabindex="0" role="textbox" aria-required="true">-->
<!--                                <div data-dx_placeholder="" class="dx-placeholder"></div>-->
<!--                            </div>-->
<!--                            <div class="dx-texteditor-buttons-container">-->
<!--                                <div></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <dx-number-box [(value)]="barcodeFooterFontSizeNumber" placeholder="Footer2 Font Size" width="100%" (onValueChanged)="barcodeFooterFontSizeNumberValueChanged($event)" (onInput)="barcodeFooterFontSizeNumberOnInput($event)" min="8" max="50">
        <dx-validator>
            <dxi-validation-rule type="required" message="Font size is required"></dxi-validation-rule>
        </dx-validator>
    </dx-number-box>

    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">
        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">
            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">
                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeFooter2">
                    <span class="dx-field-item-label-content" style="width: 80px;">
                        <span class="dx-field-item-label-text">Footer2</span>
<!--                        <span class="dx-field-item-required-mark">&nbsp;*</span>-->
                    </span>
                </label>
                <div class="dx-field-item-content dx-field-item-content-location-right">
                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">
                        <div class="dx-texteditor-container">
                            <div class="dx-texteditor-input-container">
                                <input [ngModel]="barcodeFooter2" (ngModelChange)="barcodeFooter2Change($event)" autocomplete="off" id="barcodeFooter2" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeFooter2" tabindex="0" role="textbox" aria-required="true">
                                <div data-dx_placeholder="" class="dx-placeholder"></div>
                            </div>
                            <div class="dx-texteditor-buttons-container">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!--    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">-->
<!--        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column;">-->
<!--            <div class="dx-first-row dx-first-col dx-last-col dx-field-item dx-col-0 dx-field-item-required dx-flex-layout dx-label-h-align">-->
<!--                <label class="dx-field-item-label dx-field-item-label-location-left" for="barcodeFooter2FontSize">-->
<!--                    <span class="dx-field-item-label-content" style="width: 80px;">-->
<!--                        <span class="dx-field-item-label-text">Footer2 font size</span>-->
<!--                        <span class="dx-field-item-required-mark">&nbsp;*</span>-->
<!--                    </span>-->
<!--                </label>-->
<!--                <div class="dx-field-item-content dx-field-item-content-location-right">-->
<!--                    <div class="dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-underlined dx-texteditor-empty dx-widget dx-validator dx-visibility-change-handler">-->
<!--                        <div class="dx-texteditor-container">-->
<!--                            <div class="dx-texteditor-input-container">-->
<!--                                <input [ngModel]="barcodeFooter2FontSize" (ngModelChange)="barcodeFooter2FontSizeChange($event)" autocomplete="off" id="barcodeFooter2FontSize" class="dx-texteditor-input" type="text" spellcheck="false" name="barcodeFooter2FontSize" tabindex="0" role="textbox" aria-required="true">-->
<!--                                <div data-dx_placeholder="" class="dx-placeholder"></div>-->
<!--                            </div>-->
<!--                            <div class="dx-texteditor-buttons-container">-->
<!--                                <div></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <dx-number-box [(value)]="barcodeFooter2FontSizeNumber" placeholder="Footer2 Font Size" width="100%" (onValueChanged)="barcodeFooter2FontSizeNumberValueChanged($event)" (onInput)="barcodeFooter2FontSizeNumberOnInput($event)" min="8" max="50">
        <dx-validator>
            <dxi-validation-rule type="required" message="Font size is required"></dxi-validation-rule>
        </dx-validator>
    </dx-number-box>

    <div class="dx-item dx-box-item" style="display: flex; min-height: auto; flex-grow: 1; flex-shrink: 1; margin-top: 24px;">
        <div class="dx-item-content dx-box-item-content" style="width: auto; height: auto; display: flex; flex-basis: 0px; flex-grow: 1; flex-direction: column; justify-content: flex-start;">
            <div class="dx-first-col dx-last-col dx-last-row dx-field-button-item dx-field-item dx-col-0" style="text-align: left;">
                <div class="dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text" aria-label="Распечатать" tabindex="0" role="button" (click)="barcodePrint()">
                    <div class="dx-button-content">
                        <span class="dx-button-text">Распечатать</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
