import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {LoginRequest} from '../model/login-request';
import {UtilService} from './util.service';
import {HttpClient} from '@angular/common/http';
import {catchError, map, filter, switchMap, mergeMap} from 'rxjs/operators';
import {ApiResponse} from '../model/api-response';

@Injectable()
export class AuthService {

    private checkSession$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private login$: BehaviorSubject<LoginRequest> = new BehaviorSubject<LoginRequest>(null);
    private loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    loggedIn = false;
    profile;

    constructor(
        private router: Router,
        private utilService: UtilService,
        private http: HttpClient,
    ) {
        // console.error('+++ AuthService constructor() +++');
    }

    updateCheckSession = (noNeedLogoff = false) => {
        // console.error('+++ updateCheckSession +++ noNeedLogoff:', noNeedLogoff);
        this.checkSession$.next(noNeedLogoff ? null : new Date().getTime());
    }

    onUpdateCheckSession(): Observable<boolean> {
        return this.checkSession$
            .pipe(
                filter((value) => value !== null),
                switchMap((noNeedLogoff) => {
                    // console.error('+++ onUpdateCheckSession() +++ nextValue:', nextValue);
                    // return this.http.get('https://lk.vgexpress.ru/tms/CheckSession?m=' + JSON.stringify({rnd: Math.random()}), {headers: this.utilService.headers})
                    return this.http.get('/tms/CheckSession?m=' + JSON.stringify({rnd: Math.random()}), {headers: this.utilService.headers})
                        .pipe(
                            switchMap(response => {
                                // console.error('+++ onUpdateCheckSession() +++ response:', response);
                                let result = false;
                                if (response === true) {
                                    result = true;
                                    this.loggedIn = true;
                                    return this.checkRoles()
                                        .pipe(map(() => {
                                            return true;
                                        }));
                                } else {
                                    result = false;
                                    this.loggedIn = false;
                                    // if (!noNeedLogoff) {
                                    //   this.logoff();
                                    // }
                                }
                                // this.title = result ? 'ТМС' : 'Аутентификация';
                                return of(result);
                            }),
                            catchError(error => {
                                // console.error('+++ onUpdateCheckSession() +++ catchError error:', error);
                                this.loggedIn = false;
                                // if (!noNeedLogoff) {
                                //   this.logoff();
                                // }
                                return of(false);
                            })
                        )
                        .pipe(map((logged) => {
                            // console.error('+++ onUpdateCheckSession() last +++ logged:', logged);
                            this.loggedIn$.next(logged);
                            return logged;
                        }));
                })
            );
    }

    checkRoles(): Observable<boolean> {
        return this.http.get<ApiResponse>('/tms/cwp/CheckRoles', {headers: this.utilService.headers})
            .pipe(
                map(response => {
                    this.profile = response.result;
                    return true;
                }),
                catchError(error => {
                    return of(true);
                })
            );
    }

    logoff = (callChecksession: boolean = false) => {
        // return this.http.post('https://lk.vgexpress.ru/tms/CheckSession', this.utilService.toFormUrlEncoded({logoff: true}), {headers: this.utilService.headersFormUrlencoded})
        return this.http.post('/tms/CheckSession', this.utilService.toFormUrlEncoded({logoff: true}), {headers: this.utilService.headersFormUrlencoded})
            .pipe(
                map(response => {
                    // console.error('+++ logoff() +++ response:', response);
                    if (callChecksession) {
                        this.updateCheckSession();
                    }
                    return of(null);
                }),
                catchError(error => {
                    // console.error('+++ logoff() +++ error:', error);
                    if (callChecksession) {
                        this.updateCheckSession();
                    }
                    return of(null);
                })
            );
    }

    updateLogin = (loginRequest: LoginRequest) => {
        this.login$.next(loginRequest);
    }

    onUpdateLogin(): Observable<any> {
        return this.login$
            .pipe(
                filter((value) => value !== null),
                switchMap((loginRequest) => {
                    const loginJson = {
                        j_username: loginRequest.username,
                        j_password: loginRequest.password,
                    };
                    const loginFormUrlencoded = this.utilService.toFormUrlEncoded(loginJson);
                    // const request = `j_username=${loginRequest.username}&j_password=${loginRequest.password}`;
                    // const request2 = `${encodeURIComponent('j_username')}=${encodeURIComponent(loginRequest.username)}&${encodeURIComponent('j_password')}=${encodeURIComponent(loginRequest.password)}`;
                    const request = `j%5Fusername=${encodeURIComponent(loginRequest.username)}&j%5Fpassword=${encodeURIComponent(loginRequest.password)}`;
                    // return this.http.post('https://lk.vgexpress.ru/tms/cwp/j_security_check', request, {headers: this.utilService.headersFormUrlencoded})
                    // return this.http.post('https://lk.vgexpress.ru/tms/cwp/j_security_check', encodeURIComponent(loginFormUrlencoded), {headers: this.utilService.headersFormUrlencoded})
                    // return this.http.post('https://lk.vgexpress.ru/tms/cwp/j_security_check', request, {headers: this.utilService.headersFormUrlencoded})
                    return this.http.post('/tms/cwp/j_security_check', request, {headers: this.utilService.headersFormUrlencoded})
                        .pipe(
                            map(response => {
                                // console.error('+++ onUpdateLogin() +++ response:', response);
                                this.updateCheckSession();
                                // return response;
                                return of(null);
                            }),
                            catchError(error => {
                                // console.error('+++ onUpdateLogin() +++ catchError error:', error);
                                if (error.status === 200 && error.statusText === 'OK') {
                                    this.updateCheckSession();
                                    return of(null);
                                }
                                return of('Неверно указан логин и/или пароль.');
                            })
                        );
                })
            );
    }


    logIn(login: string, passord: string) {
        this.loggedIn = true;
        this.router.navigate(['/']);
    }

    logOut() {
        this.loggedIn = false;
        this.router.navigate(['/login-form']);
    }

    get isLoggedIn() {
        return this.loggedIn;
    }

    onLoggedIn(): Observable<boolean> {
        return this.loggedIn$;
    }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const isLoggedIn = this.authService.isLoggedIn;
        const isLoginForm = route.routeConfig.path === 'login-form';

        if (isLoggedIn && isLoginForm) {
            this.router.navigate(['/']);
            return false;
        }

        if (!isLoggedIn && !isLoginForm) {
            this.router.navigate(['/login-form']);
        }

        return isLoggedIn || isLoginForm;
    }
}
