import {Component, NgModule, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {AuthService, AppInfoService} from '../../services';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxCheckBoxModule} from 'devextreme-angular/ui/check-box';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {DxValidatorModule} from 'devextreme-angular/ui/validator';
import {DxValidationGroupComponent, DxValidationGroupModule} from 'devextreme-angular/ui/validation-group';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

    @ViewChild('loginValidationGroup') loginValidationGroup: DxValidationGroupComponent;

    username = '';
    password = '';
    public error: string;
    subscriptions = new Subscription();

    constructor(private authService: AuthService, public appInfo: AppInfoService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.authService.onUpdateLogin().subscribe(response => {
            // console.error('+++ onUpdateLogin() response:', response);
            this.error = response;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.authService.updateLogin(null);
    }

    onLoginClick(args) {
        // if (!args.validationGroup.validate().isValid) {
        const validationGroup = this.loginValidationGroup.instance;
        if (!validationGroup.validate().isValid) {
            return;
        }

        // this.authService.logIn(this.login, this.password);
        this.authService.updateLogin(
            {
                username: this.username,
                password: this.password,
            }
        );

        // args.validationGroup.reset();
        validationGroup.reset();
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule
    ],
    declarations: [LoginFormComponent],
    exports: [LoginFormComponent]
})
export class LoginFormModule {
}
