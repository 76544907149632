<dx-validation-group #loginValidationGroup>
    <div class="login-header">
        <div class="title">{{appInfo.title}}</div>
        <div>Вход в аккаунт</div>
    </div>
    <div class="dx-field">
        <dx-text-box [(value)]="username" placeholder="Username" width="100%" (onEnterKey)="onLoginClick($event)">
            <dx-validator>
                <dxi-validation-rule type="required" message="Username is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-text-box mode="password" [(value)]="password" placeholder="Password" width="100%" (onEnterKey)="onLoginClick($event)">
            <dx-validator>
                <dxi-validation-rule type="required" message="Password is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
      <div>{{error}}</div>
    </div>
<!--
    <div class="dx-field">
        <dx-check-box [value]="false" text="Remember me"></dx-check-box>
    </div>
-->
    <div class="dx-field">
        <dx-button type="default" text="Login" (onClick)="onLoginClick($event)" width="100%"></dx-button>
    </div>
<!--
    <div class="dx-field">
        <a routerLink="/recovery">Forgot password ?</a>
    </div>
    <div class="dx-field">
        <dx-button type="normal" text="Create an account" width="100%"></dx-button>
    </div>
-->
</dx-validation-group>
