import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginFormComponent} from './shared/components';
import {AuthGuardService} from './shared/services';
import {HomeComponent} from './pages/home/home.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {DisplayDataComponent} from './pages/display-data/display-data.component';
// import {DxBoxModule, DxButtonModule, DxDataGridModule, DxFormModule, DxTextBoxModule} from 'devextreme-angular';
import {DxButtonModule} from 'devextreme-angular/ui/button';
import {DxBoxModule} from 'devextreme-angular/ui/box';
import {DxDataGridModule} from 'devextreme-angular/ui/data-grid';
import {DxFormModule} from 'devextreme-angular/ui/form';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {ClientsSearchComponent} from './pages/clients-search/clients-search.component';
import {CommonModule} from '@angular/common';
import {DxValidationSummaryModule} from 'devextreme-angular/ui/validation-summary';
import {RefillComponent} from './pages/refill/refill.component';
import {ClientComponent} from './pages/client/client.component';
import {ConsigneeComponent} from './pages/consignee/consignee.component';
import {DxRadioGroupModule} from 'devextreme-angular/ui/radio-group';
import {DxDateBoxModule} from 'devextreme-angular/ui/date-box';
import {DxAutocompleteModule} from 'devextreme-angular/ui/autocomplete';
import {LabelsComponent} from './pages/labels/labels.component';
// import {NgxBarcodeModule} from 'ngx-barcode';
import {FormsModule} from '@angular/forms';
import {DxNumberBoxModule} from 'devextreme-angular';
import {DxValidatorModule} from 'devextreme-angular/ui/validator';

const routes: Routes = [
    // {
    //     path: 'display-data',
    //     component: DisplayDataComponent,
    //     canActivate: [AuthGuardService]
    // },
    // {
    //     path: 'profile',
    //     component: ProfileComponent,
    //     canActivate: [AuthGuardService]
    // },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'clients',
        component: ClientsSearchComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'refill',
        component: RefillComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'labels',
        component: LabelsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'login-form',
        component: LoginFormComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: '**',
        redirectTo: 'home',
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            // { useHash: true }
        ),
        DxDataGridModule,
        DxFormModule,
        CommonModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxButtonModule,
        DxBoxModule,
        DxRadioGroupModule,
        DxDateBoxModule,
        DxAutocompleteModule,
        DxValidationSummaryModule,
        DxValidatorModule,
        // NgxBarcodeModule,
        FormsModule,
    ],
    providers: [AuthGuardService],
    exports: [RouterModule],
    declarations: [
        HomeComponent,
        ProfileComponent,
        DisplayDataComponent,
        ClientsSearchComponent,
        RefillComponent,
        ClientComponent,
        ConsigneeComponent,
        // LabelsComponent,
    ]
})
export class AppRoutingModule {
}
