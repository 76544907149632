import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import 'devextreme/data/odata/store';
import {ClientRequest} from '../../shared/model/client-request';
import {ClientsSearchService, DirectoryService} from '../../shared/services';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-client',
    templateUrl: 'client.component.html',
    styleUrls: ['client.component.scss']
})

export class ClientComponent implements OnInit, OnDestroy {

    _client: ClientRequest;
    // @Input() client: ClientRequest;
    @Input()
    set client(client: ClientRequest) {
        this._client = client;
        if (this.saveClientButtonOptions) {
            this.saveClientButtonOptions.text = (client || {}).id ? "Сохранить" : "Создать";
        }
    }

    @Output() submit = new EventEmitter<ClientRequest>();

    phoneRules: Object;

    departments;
    consignees;
    consigneeExpanded;
    consigneesComponent;
    positionExpanded;
    positionsComponent;
    consigneesCustomDataSource: DataSource;
    positionsCustomDataSource: DataSource;

    saveClientFormvalidateError: boolean = false;

    saveClientButtonOptions: any;

    constructor(
        private directoryService: DirectoryService,
        private clientsSearchService: ClientsSearchService,
    ) {
        this.phoneRules = {'X': /[12-9]/};

        this.saveClientButtonOptions = {
            text: "Создать",
            type: "success",
            // useSubmitBehavior: true,
            onClick: (event) => {
                // console.error('+++ onClick() +++ event:', event);
                this.saveClientFormvalidateError = !event.validationGroup.validate().isValid;
                if (this.saveClientFormvalidateError) {
                    return;
                }
                this.onClientFormSubmit(event.event);
            }
        };

        this.consigneesCustomDataSource = new DataSource({
            store: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                    return this.clientsSearchService.findClientConsignees((this._client || {}).id)
                        .then((consignees) => {
                            const result = [
                                ...consignees.result.first,
                                ...consignees.result.second,
                            ];
                            return result;
                        });
                    // }
                },
            })
        });

        this.positionsCustomDataSource = new DataSource({
            store: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                    return this.clientsSearchService.findClientPositions((this._client || {}).id)
                        .then((positions) => {
                            return positions.result;
                        });
                },
            })
        });
    }

    ngOnInit(): void {
        this.departments = this.directoryService.getDepartments;
        // this.clientsSearchService.findClientConsignees((this._client || {}).id)
        //     .then(response => {
        //         console.error('+++ findClientConsignees() +++ response:', response);
        //         this.consignees = response;
        //     });
        // this.client = new ClientRequest();
    }

    ngOnDestroy(): void {
    }

    onClientFormSubmit = (e) => {
        this.submit.emit(this._client);
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
        e.preventDefault();
    }

    onConsigneesRowClick(e) {
        console.error('+++ onRowClick +++ e:', e);
        // console.error('+++ onRowClick +++ this.consigneeExpanded:', this.consigneeExpanded);
        const rowKey = e.currentSelectedRowKeys[0];
        // if (this.consigneeExpanded === rowKey) {
        //     console.error('+++ onRowClick +++ rowKey:', rowKey);
        // }
        e.component.collapseAll(-1);
        if (!e.component.isRowExpanded()) {
            this.consigneeExpanded = rowKey;
            this.consigneesComponent = e.component;
            e.component.expandRow(rowKey);
        }
    }

    onConsigneeClose = (consigneeId: number) => {
        this.consigneesComponent.collapseAll(-1);
        this.consigneesComponent.deselectAll();
    }

    onPositionsRowClick(e) {
        // console.error('+++ onRowClick +++ e:', e);
        const rowKey = e.currentSelectedRowKeys[0];
        e.component.collapseAll(-1);
        if (!e.component.isRowExpanded()) {
            this.positionExpanded = rowKey;
            this.positionsComponent = e.component;
            e.component.expandRow(rowKey);
        }
    }

    onPositionClose = (positionId: number) => {
        this.positionsComponent.collapseAll(-1);
        this.positionsComponent.deselectAll();
    }

}
