import {Component, OnDestroy, OnInit} from '@angular/core';
import 'devextreme/data/odata/store';
import {HttpClient} from '@angular/common/http';
import CustomStore from "devextreme/data/custom_store";
import {ClientsSearchService} from '../../shared/services/clients-search.service';
import {map} from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';
import * as moment from 'moment';
import {Client} from '../../shared/model/client';
import {ClientRequest} from '../../shared/model/client-request';
import {DirectoryService} from '../../shared/services';
import notify from 'devextreme/ui/notify';

@Component({
    templateUrl: 'clients-search.component.html',
    styleUrls: ['clients-search.component.scss']
})

export class ClientsSearchComponent implements OnInit, OnDestroy {
    dataSource: any;
    // priority: any[];
    // customDataSource: CustomStore;
    customDataSource: DataSource;
    searchButton: any;
    saveClientButtonOptions: any;
    searchValue: string = '';

    showNewClientForm = false;

    phoneRules: Object;
    client: ClientRequest = new ClientRequest();

    departments;

    saveClientFormvalidateError: boolean = false;

    constructor(
        private http: HttpClient,
        private clientsSearchService: ClientsSearchService,
        private directoryService: DirectoryService,
    ) {
        this.phoneRules = {'X': /[12-9]/};
        this.searchButton = {
            icon: "search",
            type: "default",
            onClick: () => {
                // console.error('+++ search click +++');
                this.onSearch(null);
            }
        };

        this.saveClientButtonOptions = {
            text: "Создать",
            type: "success",
            // useSubmitBehavior: true,
            onClick: (event) => {
                // console.error('+++ onClick() +++ event:', event);
                this.saveClientFormvalidateError = !event.validationGroup.validate().isValid;
                if (this.saveClientFormvalidateError) {
                    return;
                }
                this.onClientFormSubmit(event.event);
            }
        };

        this.customDataSource = new DataSource({
            store: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                    // let params: HttpParams = new HttpParams();
                    // [
                    //     'filter',
                    //     'group',
                    //     'groupSummary',
                    //     'parentIds',
                    //     'requireGroupCount',
                    //     'requireTotalCount',
                    //     'searchExpr',
                    //     'searchOperation',
                    //     'searchValue',
                    //     'select',
                    //     'sort',
                    //     'skip',
                    //     'take',
                    //     'totalSummary',
                    //     'userData'
                    // ].forEach(function(i) {
                    //     if(i in loadOptions && isNotEmpty(loadOptions[i])) {
                    //         params[i] = JSON.stringify(loadOptions[i]);
                    //     }
                    // });

                    // return this.http.post('https://mydomain.com/MyDataService', { params: params })
                    //     .toPromise()
                    //     .then(response => {
                    //         return {
                    //             data: response.data,
                    //             totalCount: response.totalCount,
                    //             summary: response.summary,
                    //             groupCount: response.groupCount
                    //         };
                    //     })
                    //     .catch(() => { throw 'Data loading error' });
                    // console.error('+++ this.searchValue:', this.searchValue);
                    // if (this.searchValue !== '') {
                    return this.clientsSearchService.onClientsSearch(this.searchValue)
                        // .pipe(map((clients) => {
                        //     console.error('+++ clients:', clients);
                        //     return clients;
                        // }))
                        // // .subscribe((clients) => {
                        // .toPromise()
                        .then((clients) => {
                            // return {
                            //     data: clients,
                            //     totalCount: clients.length,
                            // summary: response.summary,
                            // groupCount: response.groupCount
                            // };
                            // console.error('+++ clients:', clients);
                            return clients;
                            // return {
                            //     data: clients,
                            // }
                        });
                    // }
                },
            })
        });

        // this.dataSource = {
        //     store: {
        //         type: 'odata',
        //         key: 'Task_ID',
        //         url: 'https://js.devexpress.com/Demos/DevAV/odata/Tasks'
        //     },
        //     expand: 'ResponsibleEmployee',
        //     select: [
        //         'expressNo',
        //         // 'Task_Subject',
        //         // 'Task_Start_Date',
        //         // 'Task_Due_Date',
        //         // 'Task_Status',
        //         // 'Task_Priority',
        //         // 'Task_Completion',
        //         // 'ResponsibleEmployee/Employee_Full_Name'
        //     ]
        // };

        // this.priority = [
        //   { name: 'High', value: 4 },
        //   { name: 'Urgent', value: 3 },
        //   { name: 'Normal', value: 2 },
        //   { name: 'Low', value: 1 }
        // ];
    }

    ngOnInit(): void {
        // this.clientsSearchService.updateClientsSearch('at@vgpost.ru');
        this.departments = this.directoryService.getDepartments;

    }

    ngOnDestroy(): void {
        // this.clientsSearchService.updateClientsSearch(null);
    }

    onSearch(e) {
        // Event handling commands go here
        // console.error('+++ onSearch() +++ this.searchValue:', this.searchValue);
        // this.customDataSource.load();
        this.customDataSource.reload();
    }

    onValueChanged(e) {
        // Event handling commands go here
        // console.error('+++ onSearch() +++ this.searchValue:', this.searchValue);
        // this.customDataSource.load();
        if (!this.searchValue.trim()) {
            this.customDataSource.reload();
        }
    }

    dateColumns_customizeText(cellInfo) {
        // console.error('+++ cellInfo:', cellInfo);
        return cellInfo.value ? moment(cellInfo.value).format('YYYY.MM.DD HH:mm:SS') : '';
    }

    expressNoClick = (cell, event) => {
        // console.error('+++ expressNoClick() +++ cell:', cell);
        // console.error('+++ expressNoClick() +++ cell.column:', cell.column);
        // console.error('+++ expressNoClick() +++ cell.data:', cell.data);
        const client: Client = cell.data;
        // console.error('+++ expressNoClick() +++ client:', client);
        // this.client = new ClientRequest();
        this.client = {
            department: client.department.id,
            archived: client.archived,
            removed: client.removed,
            email: client.email,
            id: client.id,
            phone: client.phone,
            comments: client.comments,
            express: client.express,
            expressNo: client.expressNo,
            passpNo: client.passpNo,
            version: client.version,
            vip: client.vip,
            autoBooking: client.autoBooking,
            // discountComment: client.discountComment,
            passpIssueDate: client.passpIssueDate,
            passpIssueOrg: client.passpIssueOrg,
            firstName: client.firstName,
            lastName: client.lastName,
            secondName: client.secondName,
            person: client.person,
            code: client.code,
            sendEmail: client.sendEmail,
            sendSms: client.sendSms,
        };
        // console.error('+++ expressNoClick() +++ this.client:', this.client);
        this.showNewClientForm = true;
        event.preventDefault();
    }

    expressNoCellTemplate_888(container, options) {
        // console.error('+++ cellInfo:', cellInfo);
        // element.append("<div>[" + info.text + "]</div>")
        //     .css('color', 'blue');
        // return cellInfo.value ? moment(cellInfo.value).format('YYYY.MM.DD HH:mm:SS') : '';
        // $("<span>")
        //     .append($("<strong>").text(options.data.shipment.code))
        //     .append($("<span>").text(' ' + options.data.shipment.date))
        //     .appendTo(container);
    }

    openNewClientForm(event) {
        this.client = new ClientRequest();
        this.showNewClientForm = !this.showNewClientForm;
    }

    onClientFormSubmit_888 = (e) => {
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
        this.clientsSearchService.saveClient(this.client)
            .then(response => {
                // console.error('+++ onClientFormSubmit() +++ response:', response);
                if (response.error) {
                    notify({ message: response.error, width: 600, shading: true }, "error", 5000);
                } else {
                    this.clientsSearchService.findClient(response.result)
                        .then(response => {
                            // console.error('+++ findClient() +++ response:', response);
                            const message = `Клиент с номером ${response.result[0].expressNo} успешно создан`;
                            notify({message: message, width: 600, shading: true}, "success", 5000);
                            this.openNewClientForm(null);
                            // this.onSearch(null);
                        });
                }
            });

        e.preventDefault();
    }

    onClientFormSubmit = (client) => {
        // console.error('+++ onClientFormSubmit() +++ this.client:', this.client);
        const _client = JSON.parse(JSON.stringify(client)) as ClientRequest;
        delete _client.expressNo;
        this.clientsSearchService.saveClient(_client)
            .then(response => {
                // console.error('+++ onClientFormSubmit() +++ response:', response);
                if (response.error) {
                    notify({ message: response.error, width: 600, shading: true }, "error", 5000);
                } else {
                    this.clientsSearchService.findClient(response.result)
                        .then(response => {
                            // console.error('+++ findClient() +++ response:', response);
                            const message = `Клиент с номером ${response.result[0].expressNo} сохранен`;
                            notify({message: message, width: 600, shading: true}, "success", 5000);
                            this.openNewClientForm(null);
                            // this.onSearch(null);
                        });
                }
            });
    }

}
