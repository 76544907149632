    <div id="form-container">
        <div *ngIf="_client?.expressNo" class="dx-field">
            <p>Номер клиента: <b>{{_client?.expressNo}}</b></p>
        </div>
        <dx-form id="form"
                 action="save-client"
                 [colCount]="1"
                 [labelLocation]="'left'"
                 [(formData)]="_client"
                 validationGroup="saveClientValidationGroup"
        >
            <dxi-item dataField="phone" [label]="{text: 'Телефон'}" [editorOptions]="{ mask: '+X (X00) 000-0000', maskRules: phoneRules }">
                <dxi-validation-rule type="required" message="Phone is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="email">
                <dxi-validation-rule
                    type="required"
                    message="Email is required">
                </dxi-validation-rule>
                <dxi-validation-rule
                    type="email"
                    message="Email is invalid">
                </dxi-validation-rule>
<!--                <dxi-validation-rule-->
<!--                    type="async"-->
<!--                    message="Email is already registered"-->
<!--                    [validationCallback]="asyncValidation">-->
<!--                </dxi-validation-rule>-->
            </dxi-item>
            <dxi-item dataField="firstName" [label]="{text: 'Имя'}">
                <dxi-validation-rule type="required" message="First name is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="lastName" [label]="{text: 'Фамилия'}">
                <dxi-validation-rule type="required" message="Last name is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="secondName" [label]="{text: 'Отчество'}">
            </dxi-item>
<!--            <dxi-item dataField="department" editorType="dxSelectBox" [editorOptions]="{ items: departments, searchEnabled: true, value: '', valueExpr: 'id', displayExpr: 'fullName' }">-->
            <dxi-item dataField="department" [label]="{text: 'Подразделние'}" editorType="dxSelectBox" [editorOptions]="{ items: departments, searchEnabled: true, value: _client.department, valueExpr: 'id', displayExpr: 'fullName' }">
                <dxi-validation-rule type="required" message="Department is required"></dxi-validation-rule>
            </dxi-item>
            <!--<div class="dx-field">
                <div class="dx-field-label">Custom templates</div>
                <div class="dx-field-value">
                    <dx-select-box
                        id="custom-templates"
                        [dataSource]="departments"
                        displayExpr="Name"
                        valueExpr="ID"
                        [value]="products[3].ID"
                        fieldTemplate="field"
                    >
                        <div *dxTemplate="let data of 'field'">
                            <div class="custom-item">
                                <img src="{{data && data.ImageSrc}}"/>
                                <dx-text-box class="product-name"
                                             [value]="data && data.Name"
                                             [readOnly]="true"
                                ></dx-text-box>
                            </div>
                        </div>
                        <div *dxTemplate="let data of 'item'">
                            <div class="custom-item">
                                <img src="{{data.ImageSrc}}" />
                                <div class="product-name">
                                    {{data.Name}}
                                </div>
                            </div>
                        </div>
                    </dx-select-box>
                </div>
            </div>-->
<!--            <dxi-item dataField="Position" editorType="dxSelectBox" [editorOptions]="{ items: positions, searchEnabled: true, value: '' }">-->
<!--                <dxi-validation-rule type="required" message="Position is required"></dxi-validation-rule>-->
<!--            </dxi-item>-->
<!--            <dxi-item dataField="HireDate" editorType="dxDateBox" [editorOptions]="{ value: null, width: '100%' }">-->
<!--                <dxi-validation-rule type="required" message="Hire date is required"></dxi-validation-rule>-->
<!--            </dxi-item>-->
<!--            <dxi-item dataField="BirthDate" editorType="dxDateBox" [editorOptions]="{ disabled: true, width: '100%' }"></dxi-item>-->
<!--            <dxi-item dataField="Address"></dxi-item>-->
<!--            <dxi-item dataField="Notes" editorType="dxTextArea" [editorOptions]="{ height: 90 }" [colSpan]="2"></dxi-item>-->
<!--            <div class="dx-fieldset">-->
                <dx-validation-summary validationGroup="saveClientValidationGroup"></dx-validation-summary>
<!--            </div>-->
            <dxi-item [label]="{text: 'Получатели'}">
<!--            <dxi-item itemType="group" caption="Получатели">-->
                <div *dxTemplate>
                    <dx-data-grid class="dx-card wide-card width-100"
                                  [dataSource]="consigneesCustomDataSource"
                                  [showColumnLines]="true"
                                  [showRowLines]="true"
                                  [showBorders]="false"
                                  [rowAlternationEnabled]="false"
                                  [focusedRowEnabled]="true"
                                  [columnAutoWidth]="true"
                                  [columnHidingEnabled]="false"
                                  [paging]="false"
                                  (onSelectionChanged)="onConsigneesRowClick($event)"
                                  [masterDetail]="{ enabled: false, template: 'consigneeDetail' }"
                    >
<!--                        (onRowClick)="onConsigneesRowClick($event)"-->
                        <dxo-selection mode="single"></dxo-selection>
                        <dxi-column
                            dataField="fullName"
                            caption="Имя"
                            [width]="190"
                        >
                        </dxi-column>
                        <dxi-column
                            dataField="phone"
                            caption="Телефон"
                            [width]="190"
                        >
                        </dxi-column>
                        <dxi-column
                            dataField="email"
                            caption="E-mail"
                            [width]="190"
                        >
                        </dxi-column>
                        <div *dxTemplate="let consignee of 'consigneeDetail'">
<!--                            <div class="employeeInfo">-->
<!--                                <p class="employeeNotes">{{consignee.data.fullName}}</p>-->
<!--                            </div>-->
                            <app-consignee [consigneeId]="consignee.data.id" (close)="onConsigneeClose($event)"></app-consignee>
                        </div>
                    </dx-data-grid>
                </div>
            </dxi-item>
            <dxi-item [label]="{text: 'Адреса доставки'}">
<!--            <dxi-item itemType="group" caption="Адреса доставки">-->
                <div *dxTemplate>
                    <dx-data-grid class="dx-card wide-card width-100"
                                  [dataSource]="positionsCustomDataSource"
                                  [showColumnLines]="true"
                                  [showRowLines]="true"
                                  [showBorders]="false"
                                  [rowAlternationEnabled]="false"
                                  [focusedRowEnabled]="true"
                                  [columnAutoWidth]="true"
                                  [columnHidingEnabled]="false"
                                  [paging]="false"
                                  (onSelectionChanged)="onPositionsRowClick($event)"
                                  [masterDetail]="{ enabled: false, template: 'positionDetail' }"
                    >
                        <dxo-selection mode="single"></dxo-selection>
                        <dxi-column
                            dataField="fullName"
                            caption="Адрес"
                            [width]="190"
                        >
                        </dxi-column>
                        <div *dxTemplate="let position of 'positionDetail'">
                            <div class="employeeInfo">
                                <p class="employeeNotes">{{position.data.fullName}}</p>
                            </div>
                        </div>
                    </dx-data-grid>
                </div>
            </dxi-item>
            <dxi-item
                itemType="button"
                alignment="left"
                [buttonOptions]="saveClientButtonOptions"
                validationGroup="saveClientValidationGroup"
            >
            </dxi-item>
<!--            <div class="dx-field">-->
<!--                <div *ngIf="saveClientFormvalidateError">Заполните обязательные поля</div>-->
<!--            </div>-->
        </dx-form>
    </div>
