import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule} from './layouts';
import {FooterModule, LoginFormModule} from './shared/components';
import {AuthService, DirectoryService, ScreenService, AppInfoService, UtilService, ClientsSearchService, AddressService} from './shared/services';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {NgxPrintModule} from 'ngx-print';
import {LabelsModule} from './pages/labels/labels.component';
// import {DxValidatorModule} from 'devextreme-angular/ui/validator';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        SideNavOuterToolbarModule,
        SideNavInnerToolbarModule,
        SingleCardModule,
        FooterModule,
        LoginFormModule,
        LabelsModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPrintModule,
        // DxValidatorModule,
    ],
    providers: [
        AuthService,
        DirectoryService,
        ScreenService,
        AppInfoService,
        UtilService,
        ClientsSearchService,
        AddressService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
