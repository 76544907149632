import {ClientShort} from './clientShort';
import {AddrObj} from './addrObj';
// import * as moment from 'moment';

export class CounterAgent {
    accNo: string;
    address: string;
    archived: boolean;
    citizenship: string;
    city: string;
    client: ClientShort;
    code: string;
    counterAgentTypes: [];
    country: string;
    defClient: boolean;
    description: string;
    email: string;
    fax: string;
    firstName: string;
    fullName: string;
    id: number;
    inn: string;
    lastName: string;
    mobile: string;
    okpo: string;
    passpIssueDate: string;
    passpIssueDateFormValue: Date;
    passpIssueOrg: string;
    passpNo: string;
    person: boolean;
    phone: string;
    phone1: string;
    postAddress: string;
    regAdministrativeAreaLvl1: string;
    regAdministrativeAreaLvl2: string;
    regApartment: string;
    regCountry: string;
    regLocality: string;
    regPostalCode: string;
    regRoute: string;
    regStreetNumber: string;
    registrationAddress: AddrObj;
    removed: boolean;
    secondName: string;
    tbe: string;
    version: number;
    zip: string;

    // get passpIssueDate(): Date | string {
    //     return moment(Number(this._passpIssueDate)).toDate();
    // }
    //
    // set passpIssueDate(date: Date | string) {
    //     console.error('+++ set passpIssueDate(date: Date | string) +++ date:', date);
    //     if (typeof date === 'string') {
    //         this._passpIssueDate = date;
    //     } else {
    //         this._passpIssueDate = moment(date).valueOf().toString();
    //     }
    // }
}
