import { Injectable } from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class UtilService {

  headers = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET')
    .set('Content-Type', 'application/json');

  headersFormUrlencoded = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET')
    .set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');

  headersFormLogin = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET')
    .set('Content-Type', 'application/x-www-form');

  toFormUrlEncoded(object): string {
    return Object.entries(object)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&');
  }

}
