<h2 class="content-block">Пополнение баланса</h2>
<div class="content-block">


    <p *ngIf="balanceRur !== null && clients && clients.length === 1">Баланс клиента {{clients[0].fullName}}: {{this.balanceRur}} руб.</p>
    <p *ngIf="clients && clients.length > 1">Найдено несколько клиентов.</p>
    <div id="form-container">
        <dx-form id="form"
                 action="refill-client"
                 [colCount]="1"
                 [labelLocation]="'left'"
                 [(formData)]="refill"
                 validationGroup="refillValidationGroup"
        >
            <dxi-item dataField="expressNo" [editorOptions]="{onValueChanged: onValueChanged}">
                <dxi-validation-rule type="required" message="Express no is required"
                ></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="comment">
                <dxi-validation-rule type="required" message="Comment is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="amount" [editorType]="'dxNumberBox'">
                <dxi-validation-rule type="required" message="Amount is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="card" editorType="dxSelectBox" [editorOptions]="{items: cards, searchEnabled: true, value: ''}">
                <dxi-validation-rule type="required" message="Card is required"></dxi-validation-rule>
            </dxi-item>
                <dx-validation-summary validationGroup="refillValidationGroup"></dx-validation-summary>
            <dxi-item
                itemType="button"
                alignment="left"
                [buttonOptions]="refillButtonOptions"
                validationGroup="refillValidationGroup"
            >
            </dxi-item>
        </dx-form>
    </div>
</div>
