<ng-container *ngIf="isAutorized(); else notAuthorized">
    <app-side-nav-outer-toolbar title="{{appInfo.title}}">
        <router-outlet></router-outlet>
        <app-footer>
            Copyright © 2020 VG
<!--            <br/>-->
<!--            All trademarks or registered trademarks are property of their respective owners.-->
        </app-footer>
    </app-side-nav-outer-toolbar>
</ng-container>

<ng-template #notAuthorized>
    <app-single-card>
        <router-outlet></router-outlet>
    </app-single-card>
</ng-template>
